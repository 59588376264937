<template>
  <v-card elevation="0">
    <v-dialog v-model="dialog" v-if="dialog" max-width="30%">
      <v-card>
        <v-card-title primary-title> Nuevo Servicio </v-card-title>
        <v-card-text>
          <v-select
            :items="$store.state.pricing.begEndList"
            v-model="servicio.id_begend"
            item-text="name"
            item-value="id"
            label="Categoria..."
          ></v-select>
          <v-text-field
            label="Descripción"
            v-model="servicio.namegroupservice"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="guardarServicio()">Guardar</v-btn>
          <v-btn color="red" @click="dialog = !dialog" dark>Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <v-card-title primary-title>
      SERVICIOS A REALIZAR:
      <v-btn icon color="primary" @click="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-subheader class="mt-0">
      <h4>Desmarque los servicios que NO desea utilizar.</h4>
    </v-subheader>

    <v-card-text>
      <v-row>
        <v-col cols="3">
          <h4 class="mb-2">ORIGEN</h4>
          <v-checkbox
            dense
            v-for="service in $store.state.pricing.listServices.filter(
              (v) => v.codebegend == 'OR'
            )"
            :key="service.id"
            v-model="service.status"
            @change="showConfirmationDialog(service)"
          >
            <template v-slot:label>
              {{ service.namegroupservice }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <h4 class="mb-2">FLETE</h4>
          <v-checkbox
            dense
            v-for="service in $store.state.pricing.listServices.filter(
              (v) => v.codebegend == 'FL'
            )"
            :key="service.id"
            v-model="service.status"
            @change="showConfirmationDialog(service)"
          >
            <template v-slot:label>
              {{ service.namegroupservice }}
            </template>
          </v-checkbox></v-col
        >
        <v-col cols="3">
          <h4 class="mb-2">DESTINO</h4>
          <v-checkbox
            dense
            v-for="service in $store.state.pricing.listServices.filter(
              (v) => v.codebegend == 'DE'
            )"
            :key="service.id"
            v-model="service.status"
            @change="showConfirmationDialog(service)"
          >
            <template v-slot:label>
              {{ service.namegroupservice }}
            </template>
          </v-checkbox></v-col
        >
        <v-col cols="3">
          <h4 class="mb-2">OPCIONAL</h4>
          <v-checkbox
            dense
            v-for="service in $store.state.pricing.listServices.filter(
              (v) => v.codebegend == 'OP'
            )"
            :key="service.id"
            v-model="service.status"
            @change="showConfirmationDialog(service)"
          >
            <template v-slot:label>
              {{ service.namegroupservice }}
            </template>
          </v-checkbox>
          <v-text-field
            prefix="$"
            :error-messages="$store.state.pricing.errorValorMercancia"
            v-model="$store.state.pricing.datosPrincipales.amount"
            label="Valor de la mercancia"
            type="number"
            step="0.10"
            min="0"
            placeholder="Valor de la mercancia..."
            autocomplete="off"
            dense
            :rules="[
              (v) => !!v || 'Dato Requerido',
              (v) =>
                /^(?!0\d+|\d*e)\d*(?:\.\d+)?$/.test(v) ||
                'Debe ser un número real entero positivo',
            ]"
            @input="recargarCostos()"
            @blur="recargarCostos()"
          >
          </v-text-field>
          <v-text-field
            v-if="
              (this.$store.state.pricing.listServices.filter(
                (v) => v.codegroupservices == 15
              ).length > 0
                ? this.$store.state.pricing.listServices.filter(
                    (v) => v.codegroupservices == 15
                  )[0].status
                : false) ||
              (this.$store.state.pricing.listServices.filter(
                (v) => v.codegroupservices == 2
              ).length > 0
                ? this.$store.state.pricing.listServices.filter(
                    (v) => v.codegroupservices == 2
                  )[0].status
                : false) ||
              (this.$store.state.pricing.listServices.filter(
                (v) => v.codegroupservices == 6
              ).length > 0
                ? this.$store.state.pricing.listServices.filter(
                    (v) => v.codegroupservices == 6
                  )[0].status
                : false)
            "
            v-model="$store.state.pricing.datosPrincipales.descripcioncarga"
            label="Descripción de carga"
            placeholder="Descripción de carga"
            autocomplete="off"
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,

      servicio: {
        codebegend: "OP",
        id_begend: "0",
        namegroupservice: "",
        status: true,
      },
    };
  },
  methods: {
    ...mapActions([
      "getItemsServices",
      "getImpuestos",
      "getItemsServicesDetails",
    ]),
    guardarServicio() {
      let codebegend = this.$store.state.pricing.begEndList.filter(
        (v) => v.id == this.servicio.id_begend
      )[0].code;
      this.servicio.codebegend = codebegend;
      this.$store.state.pricing.listServices.push(this.servicio);
      this.dialog = false;
      this.servicio = {
        codebegend: "OP",
        id_begend: "0",
        namegroupservice: "",
        status: true,
      };
    },
    recargarCostos() {
      this.$store.state.pricing.actualizarCostosFlag =
        !this.$store.state.pricing.actualizarCostosFlag;
    },
    esImportacion() {
      return this.$store.state.pricing.listModality.some(
        (v) =>
          v.id == this.$store.state.pricing.datosPrincipales.idsentido &&
          v.code == "I"
      );
    },

    showConfirmationDialog(service) {
      if (this.$store.state.pricing.listCostos.length > 0) {
        Swal.fire({
          width: 1000,
          title: "¡Advertencia!",
          html: "<h1>Esta acción borrará los costos ingresados y volverá a mostrarlos desde cero. ¿Está seguro que desea continuar?</h1>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "<h1>SÍ, RECARGAR.</h1>",
          cancelButtonText: "<h1>NO</h1>",
          cancelButtonColor: "red",
        }).then(async (res) => {
          if (res.value) {
            this.$store.state.spiner = true;
            this.$store.state.pricing.listImpuestos = [];
            this.$store.state.pricing.listCostos = [];
            await this.getItemsServicesDetails();
            await this.getImpuestos();
            this.$store.state.pricing.costoflag = false;
            this.$store.state.pricing.impuestoflag = false;
            this.$store.state.pricing.ventaflag = false;
            this.$store.state.pricing.opcionCostos = [
              {
                nro_propuesta: 1,
                date_end: "",
                tiempo_transito: 0,
                listCostos: [],
                listImpuestos: [],
                listNotasQuote: [],
                selected: false,
              },
            ];
            let nro_propuesta = 1;

            this.$store.state.pricing.opcionCostos[0].listCostos =
              await this.$store.state.pricing.listCostos.map((objeto) => ({
                ...objeto,
                nro_propuesta,
              }));
            this.$store.state.pricing.opcionCostos[0].listImpuestos =
              await this.$store.state.pricing.listImpuestos.map((objeto) => ({
                ...objeto,
                nro_propuesta,
              }));
            this.$store.state.pricing.opcionCostos[0].listNotasQuote =
              await this.$store.state.pricing.listNotasQuote.map((objeto) => ({
                ...objeto,
                nro_propuesta,
              }));
            this.$store.state.pricing.reset = !this.$store.state.pricing.reset;
            this.$store.state.pricing.costoflag = false;
            this.$store.state.pricing.ventaflag = false;
            this.$store.state.pricing.impuestoflag = false;
            this.$store.state.spiner = false;
            // if (service.codegroupservices == 15 && !service.status) {
            //   this.$store.state.pricing.listImpuestos = [];
            // }
          } else {
            service.status = !service.status;
          }
        });
      }
    },
  },
};
</script>

<style></style>
